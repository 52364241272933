import Timeline, {
  DateHeader,
  TimelineHeaders,
  TodayMarker,
} from "react-calendar-timeline";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/actions/order";
import {
  Box,
  CircularProgress,
  createStyles,
  Icon,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  addCastShift,
  fetchCastShift,
  updateCastShift,
} from "redux/actions/castShift";
import EnumUtils from "../../utils/EnumUtils";
import OrderStatus from "../../types/enum/OrderStatus";
import { fetchShops } from "redux/actions/shop";
import CastScheduleShiftItem from "./components/CastScheduleShiftItem";
import CastScheduleOrderItem from "./components/CastScheduleOrderItem";
import useModal from "../../hooks/useModal";
import CastScheduleOnItemMove from "./components/CastScheduleOnItemMove";
import OrderRes from "../../types/res/order/OrderRes";
import CastScheduleOnItemResize from "./components/CastScheduleOnItemResize";
import CreateOrderReq from "../../types/req/order/CreateOrderReq";
import CastScheduleOnItemCreate from "./components/CastScheduleOnItemCreate";
import { fetchAdditionalTime } from "redux/actions/additionalTime";
import CastScheduleOnItemMoveChangeClass from "./components/castScheduleOnItemMoveChangeClass";
import ShiftStatus from "../../types/enum/ShiftStatus";
import CastShiftRes from "../../types/res/castShift/CastShiftRes";
import CastScheduleOnShiftMove from "./components/CastScheduleOnShiftMove";
import FormModal from "../../components/FormModal";
import CreateCastShiftReq from "../../types/req/castShift/CreateCastShiftReq";
import UpdateCastShiftReq from "../../types/req/castShift/UpdateCastShiftReq";
import { FormType } from "components/FormField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import TimeUtils from "utils/TimeUtils";
import CastScheduleOnItemCreateNoCast from "pages/CastSchedule/components/CastScheduleOnItemCreateNoCast";
import styled from "styled-components";
import ShopRes from "types/res/shop/ShopRes";
import { fetchNotelClass } from "redux/actions/notelClass";
import { CastScheduleItemColor } from "types/enum/CastScheduleItemColor";
import CastRes from "types/res/cast/CastRes";
import { fetchCastCategory } from "redux/actions/castCategory";
import CastApi from "api/CastApi";
import ReactGA from "react-ga4";

const CastSchedule: React.FC = () => {
  const keys = {
    groupIdKey: "id",
    groupTitleKey: "title",
    groupRightTitleKey: "rightTitle",
    itemIdKey: "id",
    itemTitleKey: "title",
    itemDivTitleKey: "title",
    itemGroupKey: "group",
    itemTimeStartKey: "start_time",
    itemTimeEndKey: "end_time",
  };
  const Item = styled.div`
    border: 1px solid ${(props) => props.color} !important;
    border-radius: 4px;
    height: 60px !important;
    background-color: gray !important;
  `;
  const dispatch = useDispatch();
  const [castScheduleOnItemMove, setShowMoveForm] = useModal(
    "castScheduleOnItemMove"
  );
  const [castScheduleOnItemMoveChangeClass, setShowMoveChangeClassForm] =
    useModal("castScheduleOnItemMoveChangeClass");
  const [castScheduleOnItemResize, setShowResizeForm] = useModal(
    "castScheduleOnItemResize"
  );
  const [castScheduleOnItemCreate, setShowCreateForm] = useModal(
    "castScheduleOnItemCreate"
  );
  const [castScheduleOnItemCreateNoCast, setShowCreateNoCastForm] = useModal(
    "castScheduleOnItemCreateNoCast"
  );
  const [castScheduleOnShiftMove, setShowShiftMoveForm] = useModal(
    "castScheduleOnShiftMove"
  );
  const [castSchedule, setCastForm] = useModal("castSchedule");
  const companyId = useSelector((state) => state.account.staff.companyId);
  const orders = useSelector((state) => state.order);
  const castShifts = useSelector((state) => state.castShift);
  const shops = useSelector((state) => state.shop);
  const castCategories = useSelector((state) => state.castCategory);
  const notelClasses = useSelector((state) => state.notelClass);
  const [stateOrders, setStateOrders] = useState<OrderRes[]>([]);
  const [stateShifts, setStateShifts] = useState<CastShiftRes[]>([]);
  const [selectShops, setSelectShops] = useState<string[]>([]);
  const [selectNotelClasses, setSelectNotelClasses] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startTime, setStartTime] = useState<number>();
  const [endTime, setEndTime] = useState<number>();
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [minDate, setMinDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [maxDate, setMaxDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [groups, setGroups] = useState<
    (
      | {
          id: string;
          title: any;
          root: boolean;
          stackItems: boolean;
          shopId: number | null;
          height: number;
        }
      | any
    )[]
  >([]);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sort, setSort] = useState("time");
  const [filterCastName, setFilterCastName] = useState("");
  const [casts, setCasts] = useState<CastRes[]>([]);
  const forms = [
    {
      label: "キャスト名",
      key: "castId",
      type: FormType.AutoCompleteSelect,
      options: casts.map((c) => ({ id: c.castId, name: c.displayName })),
      loadMoreResults: async (displayName?: string) => {
        const offset = Math.trunc(casts.length / 100);
        const castCategoryId = castCategories.find((castCategory) =>
          castCategory.name.includes("在籍")
        )?.castCategoryId;
        const c = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: displayName ? 0 : offset,
          displayName,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts((prev) =>
          Array.from(
            new Map([...prev, ...c].map((cast) => [cast.castId, cast])).values()
          )
        );

        return c;
      },
    },
    {
      label: "店舗名",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: s.shopId, name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
    {
      label: "予定勤務開始",
      key: "planWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "予定勤務終了",
      key: "planWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "実勤務開始",
      key: "actualWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "実勤務終了",
      key: "actualWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩開始",
      key: "workOffStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩終了",
      key: "workOffEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "ステータス",
      key: "status",
      type: FormType.Select,
      options: Object.entries(ShiftStatus).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "スタッフメモ",
      key: "staffMemo",
      type: FormType.Text,
    },
    {
      label: "キャストメモ",
      key: "castMemo",
      type: FormType.Text,
    },
    {
      label: "スケジュールカラー",
      key: "scheduleItemColor",
      type: FormType.Select,
      options: Object.entries(CastScheduleItemColor).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "営業時間無視",
      key: "isForceShift",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
  ];

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  const defaultSortFunc = (
    a: any,
    b: any,
    key: string,
    direction = 1,
    nullsFirst = 1
  ) => {
    if (a[key] == undefined && b[key] == undefined) return 0;
    if (a[key] == undefined) return nullsFirst * 1;
    if (b[key] == undefined) return nullsFirst * -1;
    if (a[key] > b[key]) return direction * 1;
    if (a[key] < b[key]) return direction * -1;
    return 0;
  };
  const sortFunc = (data: any[], keys: string[]) => {
    const _data = data.slice();
    _data.sort((a, b) => {
      let order = 0;
      keys.some((key) => {
        order = defaultSortFunc(a, b, key);
        return !!order;
      });
      return order;
    });
    return _data;
  };

  useEffect(() => {
    setSelectShops([...shops.map((shop) => String(shop.shopId)), "すべて"]);
  }, [shops]);

  useEffect(() => {
    setSelectNotelClasses([
      ...notelClasses.map((notelClass) => String(notelClass.notelClassId)),
      "すべて",
    ]);
  }, [notelClasses]);

  useEffect(() => {
    window.addEventListener(
      "wheel",
      (e) => {
        if (e.deltaX) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      },
      { passive: false }
    );
  }, []);

  useEffect(() => {
    dispatch(fetchShops(companyId));
    dispatch(fetchAdditionalTime(companyId));
    dispatch(fetchNotelClass(companyId));
    const apiCall = async () => {
      const result = await fetchCastCategory(companyId)(dispatch);
      if (result) {
        const castCategoryId = result.find((r) =>
          r.name.includes("在籍")
        )?.castCategoryId;
        const castRes = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: 0,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts(castRes);
      }
    };
    apiCall();
  }, [dispatch, companyId]);

  useEffect(() => {
    const apiCall = async () => {
      setIsLoading(true);
      await dispatch(
        fetchCastShift(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
        )
      );
      await dispatch(
        fetchOrder(
          companyId,
          DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
          DateTime.fromJSDate(endDate)
            .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
            .toFormat(FORMAT_TYPE.YEAR_DAY)
        )
      );
      setIsLoading(false);
    };
    apiCall();
  }, []);

  useEffect(() => {
    setStateOrders([...orders]);
  }, [orders]);

  useEffect(() => {
    setStateShifts(castShifts);
  }, [castShifts]);

  useEffect(() => {
    let newGroups: any[] = [];
    if (sort !== "time") {
      newGroups = sortFunc(
        stateShifts
          .flatMap((castShift) => {
            return castShift.cast?.castNames
              ?.filter((group) => {
                const shopCondition = castShift.shops
                  .map((shop) => shop.shopId)
                  .includes(group.shopId);
                const classCondition = selectNotelClasses.includes("すべて")
                  ? true
                  : selectNotelClasses
                      .map((notelClass) => Number(notelClass))
                      .includes(group.notelClassId);
                const castNameCondition = !filterCastName
                  ? true
                  : group.name.includes(filterCastName);
                return shopCondition && classCondition && castNameCondition;
              })
              .flatMap((castName) => [
                {
                  id: `shop-${castName.shopId}`,
                  title: <div>{castName?.shop?.name}</div>,
                  root: true,
                  stackItems: false,
                  shopId: castName.shopId,
                  notelClassId: castName.notelClassId,
                  status:
                    EnumUtils.mapToEnum(ShiftStatus, castShift.status) ===
                    ShiftStatus.absence
                      ? 2
                      : 1,
                  height: 30,
                },
                {
                  id: `castName-${castName.castNameId}`,
                  title: (
                    <div style={{ paddingLeft: "20px" }}>
                      {castName.name}({castName?.notelClass?.name})
                    </div>
                  ),
                  root: false,
                  stackItems: true,
                  shopId: castName.shopId,
                  notelClassId: castName.notelClassId,
                  status:
                    EnumUtils.mapToEnum(ShiftStatus, castShift.status) ===
                    ShiftStatus.absence
                      ? 2
                      : 1,
                  height: 100,
                },
              ]);
          })
          .filter(Boolean),
        ["shopId", "status", "notelClassId"]
      );
    } else {
      newGroups = sortFunc(
        stateShifts
          .flatMap((castShift) => {
            return castShift.cast?.castNames
              ?.filter((group) => {
                const shopCondition = castShift.shops
                  .map((shop) => shop.shopId)
                  .includes(group.shopId);
                const classCondition = selectNotelClasses.includes("すべて")
                  ? true
                  : selectNotelClasses
                      .map((notelClass) => Number(notelClass))
                      .includes(group.notelClassId);
                const castNameCondition = !filterCastName
                  ? true
                  : group.name.includes(filterCastName);

                return shopCondition && classCondition && castNameCondition;
              })
              .flatMap((castName) => [
                {
                  id: `shop-${castName.shopId}`,
                  title: <div>{castName?.shop?.name}</div>,
                  root: true,
                  stackItems: false,
                  shopId: castName.shopId,
                  notelClassId: castName.notelClassId,
                  status:
                    EnumUtils.mapToEnum(ShiftStatus, castShift.status) ===
                    ShiftStatus.absence
                      ? -1
                      : 1,

                  height: 30,
                  startTime: 0,
                },
                {
                  id: `castName-${castName.castNameId}`,
                  title: (
                    <div style={{ paddingLeft: "20px" }}>
                      {castName.name}({castName?.notelClass?.name})
                    </div>
                  ),
                  root: false,
                  stackItems: true,
                  shopId: castName.shopId,
                  notelClassId: castName.notelClassId,
                  status:
                    EnumUtils.mapToEnum(ShiftStatus, castShift.status) ===
                    ShiftStatus.absence
                      ? 2
                      : 1,
                  height: 100,
                  startTime:
                    (
                      castShift.actualWorkEnd || castShift.planWorkEnd
                    ).getTime() < new Date().getTime()
                      ? DateTime.fromJSDate(castShift.planWorkStart)
                          .plus({ days: 2 })
                          .toJSDate()
                          .getTime()
                      : (
                          castShift.actualWorkStart || castShift.planWorkStart
                        ).getTime(),
                },
              ]);
          })
          .filter(Boolean),
        ["shopId", "status", "startTime"]
      );
    }
    const filterGroups =
      newGroups.length > 0
        ? Array.from(
            newGroups
              .reduce(
                (map, currentItem) =>
                  currentItem.root
                    ? map.set(currentItem.id, currentItem)
                    : map.set(
                        `${currentItem.id}-${currentItem.shopId}`,
                        currentItem
                      ),
                new Map()
              )
              .values()
          ).filter((group: any) =>
            selectShops
              ? selectShops.map((shop) => Number(shop)).includes(group.shopId)
              : true
          )
        : [];
    const unAssign = {
      id: `0`,
      title: <div>未アサイン</div>,
      root: false,
      stackItems: true,
      shopId: null,
      height: 30,
    };
    console.log(filterGroups);
    filterGroups.unshift(unAssign);
    setGroups(filterGroups);
  }, [stateShifts, selectShops, selectNotelClasses, sort, filterCastName]);

  useEffect(() => {
    if (!shops.length) return;
    setStartTime(
      Number(
        shops.reduce(
          (a, b) =>
            a < Number(b.openingTime.split(":")[0])
              ? a
              : Number(b.openingTime.split(":")[0]),
          24
        )
      )
    );
    setEndTime(TimeUtils.maxClosingTimeInShop(shops));
  }, [shops]);

  useEffect(() => {
    const startAndMinDate = DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .startOf("day")
      .plus({ hours: startTime })
      .toJSDate();
    const endAndMaxDate = DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .startOf("day")
      .plus({ hours: endTime })
      .toJSDate();
    setStartDate(startAndMinDate);
    setEndDate(endAndMaxDate);
  }, [startTime, endTime]);

  useEffect(() => {
    setLoading(true);
    const items = stateOrders
      .filter(
        (order) =>
          EnumUtils.mapToEnum(OrderStatus, order.status) !==
            OrderStatus.cancel &&
          (order.departureTime || order.planInTime) &&
          order.courseTime
      )
      .map((order) => ({
        id: `order-${order.orderId}`,
        group: order?.castNameId ? `castName-${order.castNameId}` : 0,
        title: `${order.guest?.name || "ゲスト未設定"}(${
          order.hotel?.name || ""
        })`,
        color: itemColor(order),
        textColor: order?.isSentEmailToCast ? "red" : "",
        start_time: order?.actualInTime
          ? Number(DateTime.fromJSDate(order.actualInTime).toFormat("x"))
          : Number(
              DateTime.fromJSDate(
                order.departureTime || order.planInTime || new Date()
              ).toFormat("x")
            ),
        end_time: Number(
          order.actualEndTime
            ? DateTime.fromJSDate(order.actualEndTime).toFormat("x")
            : order.planOutTime
            ? DateTime.fromJSDate(order.planOutTime).toFormat("x")
            : DateTime.fromJSDate(
                order.departureTime || order.planInTime || new Date()
              )
                .plus({ minutes: order.courseTime || 0 })
                .toFormat("x")
        ),
        canChangeGroup: true,
        canMove: true,
        canResize: "right",
      }));
    const copyItems = stateOrders
      .filter(
        (order) =>
          EnumUtils.mapToEnum(OrderStatus, order.status) !==
            OrderStatus.cancel &&
          (order.departureTime || order.planInTime) &&
          order.courseTime
      )
      .flatMap((order) => {
        const cast = castShifts.find((castShift) =>
          castShift.cast?.castNames
            .map((castName) => castName?.castNameId)
            .includes(order?.castNameId || 0)
        )?.cast;
        if (!cast) return;
        return cast.castNames.flatMap((castName) => {
          if (castName.castNameId === order.castNameId) return;
          return {
            id: `copyOrder-${order.orderId}-${castName.castNameId}`,
            group: `castName-${castName.castNameId}`,
            title: `${order.shop?.name || ""} ${order.castName?.name || ""}`,
            color: "#949494",
            start_time: order?.actualInTime
              ? Number(DateTime.fromJSDate(order.actualInTime).toFormat("x"))
              : Number(
                  DateTime.fromJSDate(
                    order.departureTime || order.planInTime || new Date()
                  ).toFormat("x")
                ),
            end_time: Number(
              order.actualEndTime
                ? DateTime.fromJSDate(order.actualEndTime).toFormat("x")
                : order.planOutTime
                ? DateTime.fromJSDate(order.planOutTime).toFormat("x")
                : DateTime.fromJSDate(
                    order.departureTime || order.planInTime || new Date()
                  )
                    .plus({ minutes: order.courseTime || 0 })
                    .toFormat("x")
            ),
            canChangeGroup: false,
            canMove: false,
            canResize: false,
          };
        });
      })
      .filter(Boolean);
    const allItems = [...items, ...copyItems];
    const shift = stateShifts
      .flatMap((shift) => {
        return shift.cast?.castNames?.flatMap((castName) => ({
          id: `shift-${shift.castShiftId}-${castName.castNameId}`,
          group: `castName-${castName.castNameId}`,
          title: `${
            EnumUtils.mapToEnum(ShiftStatus, shift.status) ===
            ShiftStatus.absence
              ? "当日欠勤"
              : shift.actualWorkStart
              ? "実出勤"
              : "出勤予定"
          }`,
          color: shift.scheduleItemColor
            ? shift.scheduleItemColor
            : shiftColor(shift.status),
          start_time: shiftStartTime(shift, castName.shop),
          end_time: shiftEndTime(shift, castName.shop),
          castShift: shift,
          canChangeGroup: false,
          canMove: true,
          canResize: "both",
        }));
      })
      .filter(Boolean);
    const rests = stateShifts
      .flatMap((shift) => {
        if (shift.workOffStart && shift.workOffEnd) {
          return shift.cast?.castNames?.flatMap((castName) => ({
            id: `rest-${shift.castShiftId}-${castName.castNameId}`,
            group: `castName-${castName.castNameId}`,
            title: "休憩",
            color: "#949494",
            start_time: Number(
              DateTime.fromJSDate(shift.workOffStart || new Date()).toFormat(
                "x"
              )
            ),
            end_time: Number(
              DateTime.fromJSDate(shift.workOffEnd || new Date()).toFormat("x")
            ),
            castShift: shift,
            canChangeGroup: false,
            canMove: false,
            canResize: false,
          }));
        }
      })
      .filter(Boolean);
    const allShift = [...shift, ...rests];
    const notBusinessHoursBefore = stateShifts
      .flatMap((shift) => {
        return shift.cast?.castNames.flatMap((castName) => {
          if (!castName.shop) return;
          return {
            id: `businessHourBefore-${castName.castNameId}`,
            group: `castName-${castName.castNameId}`,
            title: "営業時間外",
            start_time: Number(
              DateTime.fromJSDate(startDate).startOf("day").toFormat("x")
            ),
            end_time: Number(
              DateTime.fromJSDate(startDate)
                .startOf("day")
                .plus({
                  hours: Number(castName.shop.openingTime.split(":")[0]),
                })
                .toFormat("x")
            ),
            canChangeGroup: false,
            canMove: false,
            canResize: false,
          };
        });
      })
      .filter(Boolean);
    const notBusinessHoursAfter = stateShifts
      .flatMap((shift) => {
        return shift.cast?.castNames.flatMap((castName) => {
          if (!castName.shop) return;
          return {
            id: `businessHourAfter-${castName.castNameId}`,
            group: `castName-${castName.castNameId}`,
            title: "営業時間外",
            start_time: Number(
              DateTime.fromJSDate(startDate)
                .startOf("day")
                .plus({
                  hours:
                    Number(castName.shop.closingTime.split(":")[0]) < 12
                      ? Number(castName.shop.closingTime.split(":")[0]) + 25
                      : Number(castName.shop.closingTime.split(":")[0]) + 1,
                })
                .toFormat("x")
            ),
            end_time: Number(
              DateTime.fromJSDate(endDate).endOf("day").toFormat("x")
            ),
            canChangeGroup: false,
            canMove: false,
            canResize: false,
          };
        });
      })
      .filter(Boolean);
    const notBusinessHours = [
      ...notBusinessHoursBefore,
      ...notBusinessHoursAfter,
    ];
    setItems([...allItems, ...allShift, ...notBusinessHours]);
    setLoading(false);
  }, [stateShifts, stateOrders]);

  const shiftStartTime = (shift: CastShiftRes, shop: ShopRes) => {
    if (!shop) return;
    if (shift.isForceShift) {
      return Number(
        DateTime.fromJSDate(
          shift?.actualWorkStart || shift?.planWorkStart || new Date()
        ).toFormat("x")
      );
    }
    return Number(
      DateTime.fromJSDate(
        shift?.actualWorkStart || shift?.planWorkStart || new Date()
      ).toFormat("x") <
        DateTime.fromJSDate(startDate)
          .startOf("day")
          .plus({
            hours: Number(shop.openingTime.split(":")[0]),
          })
          .toFormat("x")
        ? DateTime.fromJSDate(startDate)
            .startOf("day")
            .plus({
              hours: Number(shop.openingTime.split(":")[0]),
            })
            .toFormat("x")
        : DateTime.fromJSDate(
            shift?.actualWorkStart || shift?.planWorkStart || new Date()
          ).toFormat("x")
    );
  };

  const shiftEndTime = (shift: CastShiftRes, shop: ShopRes) => {
    if (!shop) return;
    if (shift.isForceShift) {
      return Number(
        DateTime.fromJSDate(
          shift?.actualWorkEnd || shift?.planWorkEnd || new Date()
        ).toFormat("x")
      );
    }
    return Number(
      DateTime.fromJSDate(
        shift?.actualWorkEnd || shift?.planWorkEnd || new Date()
      ).toFormat("x") >
        DateTime.fromJSDate(startDate)
          .startOf("day")
          .plus({
            hours:
              Number(shop.closingTime.split(":")[0]) < 12
                ? Number(shop.closingTime.split(":")[0]) + 25
                : Number(shop.closingTime.split(":")[0]) + 1,
          })
          .toFormat("x")
        ? DateTime.fromJSDate(startDate)
            .startOf("day")
            .plus({
              hours:
                Number(shop.closingTime.split(":")[0]) < 12
                  ? Number(shop.closingTime.split(":")[0]) + 25
                  : Number(shop.closingTime.split(":")[0]) + 1,
            })
            .toFormat("x")
        : DateTime.fromJSDate(
            shift?.actualWorkEnd || shift?.planWorkEnd || new Date()
          ).toFormat("x")
    );
  };

  const itemColor = (order: OrderRes) => {
    if (EnumUtils.mapToEnum(OrderStatus, order.status) === OrderStatus.paid) {
      return "#93c47d";
    }
    if (EnumUtils.mapToEnum(OrderStatus, order.status) === OrderStatus.hold) {
      return order?.planOutTime && order.planOutTime > new Date()
        ? "#ffe599"
        : "#d5a6bd";
    }
    if (
      EnumUtils.mapToEnum(OrderStatus, order.status) === OrderStatus.booking
    ) {
      if (order?.requestActualInTime && !order?.actualInTime) {
        return "#f53737";
      }
      return order?.planOutTime && order.planOutTime > new Date()
        ? "#6d9eeb"
        : "#ea9999";
    }
  };

  const shiftColor = (shiftStatus: ShiftStatus) => {
    switch (EnumUtils.mapToEnum(ShiftStatus, shiftStatus)) {
      case ShiftStatus.unconfirmed:
        return "#e8dc45";
      case ShiftStatus.verified:
        return "#30c91c";
      case ShiftStatus.tardy:
        return "#800080";
      case ShiftStatus.absence:
        return "#949494";
    }
  };

  const onTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void
  ) => {
    const minTime = Number(DateTime.fromJSDate(minDate).toFormat("x"));
    const maxTime = Number(DateTime.fromJSDate(maxDate).toFormat("x"));
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(
        minTime,
        minTime + (visibleTimeEnd - visibleTimeStart)
      );
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(
        maxTime - (visibleTimeEnd - visibleTimeStart),
        maxTime
      );
    }
  };

  const onItemMove = (
    itemId: string,
    dragTime: number,
    newGroupOrder: number
  ) => {
    if (itemId.split("-")[0] === "order") {
      if (newGroupOrder === 0) return alert("未アサインには移動できません。");
      const group = groups[newGroupOrder];
      if (group.root) return alert("店舗には移動できません。");
      const defaultOrders: any[] = orders.map((order) => ({ ...order }));
      const order = defaultOrders.find(
        (order: OrderRes) => order.orderId === Number(itemId.split("-")[1])
      );
      if (!order) return;
      const castNameId = Number(group.id.split("-")[1]);
      const castName = castShifts
        .find((castShift) =>
          castShift.cast.castNames.find(
            (castName) => castName.castNameId === castNameId
          )
        )
        ?.cast?.castNames?.find(
          (castName) => castName.castNameId === castNameId
        );
      if (!castName) return;
      if (order?.castName?.notelClassId !== castName.notelClassId) {
        return setShowMoveChangeClassForm(true, {
          type: "castScheduleOnItemMoveChangeClass",
          order,
          castName,
        });
      }
      if (order.departureTime && order.planInTime) {
        const diffTIme = DateTime.fromJSDate(order.planInTime).diff(
          DateTime.fromJSDate(order.departureTime),
          "minutes"
        ).minutes;
        order.departureTime = DateTime.fromMillis(dragTime).toJSDate();
        order.planInTime = DateTime.fromMillis(dragTime)
          .plus({ minutes: diffTIme })
          .toJSDate();
      } else if (order.departureTime) {
        order.departureTime = DateTime.fromMillis(dragTime).toJSDate();
        order.planInTime = null;
      } else if (order.planInTime) {
        order.departureTime = null;
        order.planInTime = DateTime.fromMillis(dragTime).toJSDate();
      }
      order.planOutTime = DateTime.fromJSDate(
        order.planInTime || order.departureTime
      )
        .plus({ minutes: order.totalTime || 0 })
        .toJSDate();
      order.castNameId = castNameId;
      setStateOrders([
        ...defaultOrders.map((item: OrderRes) =>
          item.orderId === order.orderId ? order : item
        ),
      ]);
      setShowMoveForm(true, {
        type: "castScheduleOnItemMove",
        order,
        castName,
      });
    } else {
      const shiftId = Number(itemId.split("-")[1]);
      const defaultShifts = castShifts.map((shift) => ({ ...shift }));
      const castShift: any = defaultShifts.find(
        (shift) => shift.castShiftId === shiftId
      );
      if (!castShift) return;
      const diff = DateTime.fromJSDate(
        castShift?.actualWorkEnd
          ? castShift.actualWorkEnd
          : castShift.planWorkEnd
      ).diff(
        DateTime.fromJSDate(
          castShift?.actualWorkStart
            ? castShift.actualWorkStart
            : castShift.planWorkStart
        ),
        "minutes"
      ).minutes;
      if (castShift?.actualWorkEnd) {
        castShift.actualWorkEnd = DateTime.fromMillis(dragTime)
          .plus({ minutes: diff })
          .toJSDate();
      } else {
        castShift.planWorkEnd = DateTime.fromMillis(dragTime)
          .plus({ minutes: diff })
          .toJSDate();
      }

      if (castShift?.actualWorkStart) {
        castShift.actualWorkStart = DateTime.fromMillis(dragTime).toJSDate();
      } else {
        castShift.planWorkStart = DateTime.fromMillis(dragTime).toJSDate();
      }

      setStateShifts([
        ...defaultShifts.map((shift) =>
          shift.castShiftId === castShift.castShiftId ? castShift : shift
        ),
      ]);
      setShowShiftMoveForm(true, {
        type: "castScheduleOnShiftMove",
        castShift,
      });
    }
  };

  const onItemResize = (
    itemId: string,
    time: number,
    edge: "left" | "right"
  ) => {
    if (itemId.split("-")[0] === "order") {
      const defaultOrders: any[] = orders.map((order) => ({ ...order }));
      const order = defaultOrders.find(
        (order: OrderRes) => order.orderId === Number(itemId.split("-")[1])
      );
      if (!order) return;
      if (edge === "right") {
        order.planOutTime = DateTime.fromMillis(time).toJSDate();
      } else {
        order.planInTime = DateTime.fromMillis(time).toJSDate();
      }
      setStateOrders([
        ...defaultOrders.map((item: OrderRes) =>
          item.orderId === order.orderId ? order : item
        ),
      ]);
      setShowResizeForm(true, {
        type: "castScheduleOnItemResize",
        order,
      });
    } else {
      const shiftId = Number(itemId.split("-")[1]);
      const defaultShifts = castShifts.map((shift) => ({ ...shift }));
      const castShift: any = defaultShifts.find(
        (shift) => shift.castShiftId === shiftId
      );
      if (!castShift) return;
      if (castShift?.actualWorkEnd) {
        if (edge === "right") {
          castShift.actualWorkEnd = DateTime.fromMillis(time).toJSDate();
        } else {
          castShift.actualWorkStart = DateTime.fromMillis(time).toJSDate();
        }
      } else {
        if (edge === "right") {
          castShift.planWorkEnd = DateTime.fromMillis(time).toJSDate();
        } else {
          castShift.planWorkStart = DateTime.fromMillis(time).toJSDate();
        }
      }
      setStateShifts([
        ...defaultShifts.map((shift) =>
          shift.castShiftId === castShift.castShiftId ? castShift : shift
        ),
      ]);
      setShowShiftMoveForm(true, {
        type: "castScheduleOnShiftMove",
        castShift,
      });
    }
  };

  const onCanvasClick = (
    groupId: string,
    time: number,
    e: React.SyntheticEvent
  ) => {
    const order = {} as CreateOrderReq;
    order.departureTime = DateTime.fromMillis(time).toJSDate();
    order.planInTime = DateTime.fromMillis(time).toJSDate();
    order.planOutTime = DateTime.fromMillis(time)
      .plus({ minutes: 60 })
      .toJSDate();
    order.orderDate = DateTime.fromMillis(time).toJSDate();
    order.status = OrderStatus.hold;
    if (groupId.split("-")[0] !== "castName") {
      return setShowCreateNoCastForm(true, {
        type: "castScheduleOnItemCreateNoCast",
        order,
      });
    }
    const castNameId = Number(groupId.split("-")[1]);
    const castName = castShifts
      .find((castShift) =>
        castShift.cast.castNames
          .map((castName) => castName.castNameId)
          .includes(castNameId)
      )
      ?.cast?.castNames?.find((castName) => castName.castNameId === castNameId);
    if (!castName) return;
    order.castNameId = castName.castNameId;
    ReactGA.event({
      action: `canvasDoubleClick-${castName.companyId}`,
      category: `castSchedule`,
      value: 1,
    });
    setShowCreateForm(true, {
      type: "castScheduleOnItemCreate",
      order,
      castName,
    });
  };

  const itemRenderer = (
    item: any,
    itemContext: any,
    getItemProps: any,
    getResizeProps: any
  ) => {
    if (item.id.split("-")[0] === "shift" || item.id.split("-")[0] === "rest") {
      return (
        <CastScheduleShiftItem
          item={item}
          getItemProps={getItemProps}
          itemContext={itemContext}
          onClick={() => onClickEditForm(item)}
        />
      );
    }
    if (
      item.id.split("-")[0] === "businessHourBefore" ||
      item.id.split("-")[0] === "businessHourAfter"
    ) {
      return (
        <Item {...getItemProps(item.itemProps)} color={item.color}>
          <div
            className="rct-item-content"
            style={{ maxHeight: `${itemContext.dimensions.height}` }}
          >
            {itemContext.title}
          </div>
        </Item>
      );
    }
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const order = orders.find(
      (order) => order.orderId === Number(item.id.split("-")[1])
    );
    if (!order) return <></>;
    return (
      <CastScheduleOrderItem
        order={order}
        item={item}
        itemContext={itemContext}
        getItemProps={getItemProps}
        rightResizeProps={rightResizeProps}
        leftResizeProps={leftResizeProps}
      />
    );
  };

  const onClickCreateForm = () => {
    setCastForm(true, { type: "addData", item: { status: "unconfirmed" } });
  };

  const onClickEditForm = (item: any) => {
    setCastForm(true, { type: "editData", item: item.castShift });
  };

  const onClickReload = () => {
    dispatch(
      fetchCastShift(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
    dispatch(
      fetchOrder(
        companyId,
        DateTime.fromJSDate(startDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        DateTime.fromJSDate(endDate)
          .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
          .toFormat(FORMAT_TYPE.YEAR_DAY)
      )
    );
  };

  if (loading) return <CircularProgress />;
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            type="date"
            label="開始日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(minDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) => {
              const datetime = DateTime.fromISO(event.target.value as string)
                .startOf("day")
                .plus({ hours: startTime })
                .toJSDate();
              const nextDatetime = DateTime.fromJSDate(datetime)
                .startOf("day")
                .plus({ hours: endTime })
                .toJSDate();
              setMinDate(datetime);
              setStartDate(datetime);
              setMaxDate(nextDatetime);
              setEndDate(nextDatetime);
              dispatch(
                fetchOrder(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime)
                    .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
                    .toFormat(FORMAT_TYPE.YEAR_DAY)
                )
              );
              dispatch(
                fetchCastShift(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                )
              );
            }}
          />
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(maxDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) => {
              const nextDatetime = DateTime.fromISO(
                event.target.value as string
              )
                .startOf("day")
                .plus({ hours: endTime })
                .toJSDate();
              const datetime = DateTime.fromJSDate(nextDatetime)
                .startOf("day")
                .plus({ hours: startTime })
                .toJSDate();
              setMinDate(datetime);
              setStartDate(datetime);
              setMaxDate(nextDatetime);
              setEndDate(nextDatetime);
              dispatch(
                fetchOrder(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime)
                    .plus({ days: TimeUtils.isInAllDays(shops) ? 1 : 0 })
                    .toFormat(FORMAT_TYPE.YEAR_DAY)
                )
              );
              dispatch(
                fetchCastShift(
                  companyId,
                  DateTime.fromJSDate(datetime).toFormat(FORMAT_TYPE.YEAR_DAY),
                  DateTime.fromJSDate(nextDatetime).toFormat(
                    FORMAT_TYPE.YEAR_DAY
                  )
                )
              );
            }}
          />
          <Box>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={selectShops}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectShops((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length === shops.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...shops.map((shop) => String(shop.shopId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= shops.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectShops.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={selectShops.indexOf(String(shop.shopId)) !== -1}
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-multiple-notel-class">クラス</InputLabel>
            <Select
              multiple
              value={selectNotelClasses}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectNotelClasses((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...notelClasses.map((notelClass) =>
                        String(notelClass.notelClassId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      notelClasses.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...notelClasses.map((notelClass) =>
                        String(notelClass.notelClassId)
                      ),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <=
                    notelClasses.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-notelClass" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return notelClasses
                    .filter((notelClass) =>
                      (selected as string[]).includes(
                        String(notelClass.notelClassId)
                      )
                    )
                    .map((notelClass) => notelClass.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectNotelClasses.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {notelClasses.map((notelClass) => (
                <MenuItem
                  key={notelClass.notelClassId}
                  value={String(notelClass.notelClassId)}
                >
                  <Checkbox
                    checked={
                      selectNotelClasses.indexOf(
                        String(notelClass.notelClassId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={notelClass.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="select-sort">並び順</InputLabel>
            <Select
              value={sort}
              onChange={(event) => {
                setSort(String(event.target.value));
              }}
              input={<Input id="select-sort" />}
              style={{ width: "200px", marginRight: "10px" }}
            >
              <MenuItem key={"class"} value={"class"}>
                <ListItemText primary={"クラス順"} />
              </MenuItem>
              <MenuItem key={"time"} value={"time"}>
                <ListItemText primary={"時間順"} />
              </MenuItem>
            </Select>
          </Box>
          <TextField
            value={filterCastName}
            onChange={(event) => {
              setFilterCastName(event.target.value);
            }}
            label="キャスト名絞り込み"
            style={{ margin: "10px", width: "200px" }}
          />
          <Box p={1} display="flex">
            <Button
              variant="contained"
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              color="secondary"
              onClick={() => onClickCreateForm()}
            >
              追加
            </Button>
          </Box>
          <Box p={1} display="flex">
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={() => onClickReload()}
            >
              データ更新
            </Button>
          </Box>
        </Box>
      </Box>
      <div style={{ width: "100vw" }}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Timeline
            groups={groups}
            items={items}
            keys={keys}
            visibleTimeStart={DateTime.fromJSDate(startDate).toMillis()}
            visibleTimeEnd={DateTime.fromJSDate(endDate)
              .plus({ days: 1 })
              .toMillis()}
            stackItems
            onTimeChange={onTimeChange}
            onItemResize={(itemId, time, edge) =>
              onItemResize(itemId as string, time, edge)
            }
            onItemMove={(itemId, dragTime, newGroupOrder) =>
              onItemMove(itemId as string, dragTime, newGroupOrder)
            }
            itemRenderer={({
              item,
              itemContext,
              getItemProps,
              getResizeProps,
            }) => itemRenderer(item, itemContext, getItemProps, getResizeProps)}
            onCanvasDoubleClick={(groupId, time, e) =>
              onCanvasClick(groupId as string, time, e)
            }
            horizontalLineClassNamesForGroup={(group) =>
              group.root ? ["rct-row-root"] : []
            }
            groupRenderer={({ group }) => (
              <div className={group.root ? "rct-row-root" : ""}>
                <div className="title">
                  <Typography style={{ whiteSpace: "pre-wrap" }}>
                    {group.title}
                  </Typography>
                </div>
              </div>
            )}
          >
            <TodayMarker date={DateTime.local().toJSDate()}>
              {({ styles }) => (
                <div style={{ ...styles, backgroundColor: "red" }} />
              )}
            </TodayMarker>
            <TimelineHeaders
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1200,
              }}
            >
              <DateHeader
                unit="day"
                labelFormat="YYYY年MM月DD日"
                style={{ pointerEvents: "none" }}
              />
              <DateHeader
                unit="hour"
                labelFormat="HH:00"
                style={{ pointerEvents: "none" }}
              />
            </TimelineHeaders>
          </Timeline>
        )}
        {castScheduleOnItemMove?.show && (
          <CastScheduleOnItemMove
            setStateOrders={setStateOrders}
            orders={[...orders]}
          />
        )}
        {castScheduleOnItemMoveChangeClass?.show && (
          <CastScheduleOnItemMoveChangeClass
            setStateOrders={setStateOrders}
            orders={[...orders]}
          />
        )}
        {castScheduleOnItemResize?.show && (
          <CastScheduleOnItemResize
            setStateOrders={setStateOrders}
            orders={orders}
          />
        )}
        {castScheduleOnItemCreate?.show && (
          <CastScheduleOnItemCreate
            setStateOrders={setStateOrders}
            orders={orders}
          />
        )}
        {castScheduleOnItemCreateNoCast?.show && (
          <CastScheduleOnItemCreateNoCast
            setStateOrders={setStateOrders}
            orders={orders}
          />
        )}
        {castScheduleOnShiftMove?.show && (
          <CastScheduleOnShiftMove
            setStateShifts={setStateShifts}
            shifts={castShifts}
          />
        )}
        {castSchedule?.show && (
          <FormModal<CreateCastShiftReq, UpdateCastShiftReq>
            formId="castSchedule"
            title="キャストシフト"
            forms={forms}
            addType={CreateCastShiftReq}
            addFunc={(formData) => addCastShift(companyId, formData)}
            updateType={UpdateCastShiftReq}
            updateFunc={(formData) => updateCastShift(companyId, formData)}
          />
        )}
      </div>
    </>
  );
};
export default CastSchedule;
