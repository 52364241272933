import RequestUtils from "utils/RequestUtils";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import CreateCastShiftReq from "types/req/castShift/CreateCastShiftReq";
import UpdateCastShiftReq from "types/req/castShift/UpdateCastShiftReq";
import DeleteCastShiftReq from "types/req/castShift/DeleteCastShiftReq";
import querystring from "querystring";
import BulkInsertCastShiftReq from "../types/req/castShift/BulkInsertCastShiftReq";

export default class CastShiftApi {
  private constructor() {}

  static async findAll(
    companyId: number,
    startDate: string,
    endDate: string,
    shopId?: number,
    castNameId?: number
  ): Promise<CastShiftRes[]> {
    const query = { shopId, castNameId };
    return await RequestUtils.getArray(
      CastShiftRes,
      `/castShift/findAll/${companyId}/${startDate}/${endDate}?${querystring.stringify(
        query
      )}`
    );
  }

  static async findAllWithPagination(
    companyId: number,
    startDate: string,
    endDate: string,
    limit?: number,
    offset?: number
  ): Promise<CastShiftRes[]> {
    const query = { limit, offset };
    return await RequestUtils.getArray(
      CastShiftRes,
      `/castShift/findAllWithPagination/${companyId}/${startDate}/${endDate}?${querystring.stringify(
        query
      )}`
    );
  }

  static async count(companyId: number, startDate: string, endDate: string) {
    return await RequestUtils.count(
      `/castShift/count/${companyId}/${startDate}/${endDate}`
    );
  }

  static async create(companyId: number, data: CreateCastShiftReq) {
    return await RequestUtils.post(
      CastShiftRes,
      `/castShift/create/${companyId}`,
      data
    );
  }

  static async update(
    companyId: number,
    data: UpdateCastShiftReq
  ): Promise<CastShiftRes> {
    return await RequestUtils.post(
      CastShiftRes,
      `/castShift/update/${companyId}`,
      data
    );
  }

  static async delete(companyId: number, data: DeleteCastShiftReq) {
    return await RequestUtils.postVoid(`/castShift/delete/${companyId}`, data);
  }

  static async bulkInsert(companyId: number, data: BulkInsertCastShiftReq[]) {
    return await RequestUtils.postArray(
      CastShiftRes,
      `/castShift/bulkInsert/${companyId}`,
      data
    );
  }
}
