import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import {
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { DateTime } from "luxon";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ShiftStatus from "types/enum/ShiftStatus";
import { CastScheduleItemColor } from "types/enum/CastScheduleItemColor";
import CreateCastShiftReq from "types/req/castShift/CreateCastShiftReq";
import CastShiftApi from "api/CastShiftApi";
import UpdateCastShiftReq from "types/req/castShift/UpdateCastShiftReq";

const Label = styled(Typography)`
  width: 40%;
`;

type Props = {
  apiCall: any;
};

const SpCastShiftModal = ({ apiCall }: Props) => {
  const [spCastShiftModal, setSpCastShiftModal] = useModal("spCastShiftModal");
  const [castShiftReq, setCastShiftReq] = useState<any>({});
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const companyId = useSelector((state) => state.account.staff.companyId);
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const minutes = ["00", "15", "30", "45"];
  const hours = [...Array(24 + changeDate.hour)].map((_, i) =>
    String(i).padStart(2, "0")
  );

  useEffect(() => {
    if (!spCastShiftModal.meta?.castName?.cast?.castShifts?.length) {
      return setCastShiftReq({ status: "unconfirmed" });
    }
    const baseDate = DateTime.fromJSDate(spCastShiftModal.meta.date);
    const castShift = spCastShiftModal.meta.castName.cast.castShifts[0];
    const planWorkStart = DateTime.fromJSDate(castShift.planWorkStart);
    const planWorkEnd = DateTime.fromJSDate(castShift.planWorkEnd);
    const actualWorkStart = castShift.actualWorkStart
      ? DateTime.fromJSDate(castShift.actualWorkStart)
      : undefined;
    const actualWorkEnd = castShift.actualWorkEnd
      ? DateTime.fromJSDate(castShift.actualWorkEnd)
      : undefined;

    const workOffStart = castShift.workOffStart
      ? DateTime.fromJSDate(castShift.workOffStart)
      : undefined;
    const workOffEnd = castShift.workOffEnd
      ? DateTime.fromJSDate(castShift.workOffEnd)
      : undefined;
    setCastShiftReq({
      planWorkStartHour: String(
        planWorkStart.diff(baseDate, "days").days < 1
          ? planWorkStart.hour
          : planWorkStart.hour + 24
      ).padStart(2, "0"),
      planWorkStartMinute: String(planWorkStart.minute).padStart(2, "0"),
      planWorkEndHour: String(
        planWorkEnd.diff(baseDate, "days").days < 1
          ? planWorkEnd.hour
          : planWorkEnd.hour + 24
      ).padStart(2, "0"),
      planWorkEndMinute: String(planWorkEnd.minute).padStart(2, "0"),
      actualWorkStartHour: actualWorkStart
        ? String(
            actualWorkStart.diff(baseDate, "days").days < 1
              ? actualWorkStart.hour
              : actualWorkStart.hour + 24
          ).padStart(2, "0")
        : "",
      actualWorkStartMinute: actualWorkStart
        ? String(actualWorkStart.minute).padStart(2, "0")
        : "",
      actualWorkEndHour: actualWorkEnd
        ? String(
            actualWorkEnd.diff(baseDate, "days").days < 1
              ? actualWorkEnd.hour
              : actualWorkEnd.hour + 24
          ).padStart(2, "0")
        : "",
      actualWorkEndMinute: actualWorkEnd
        ? String(actualWorkEnd.minute).padStart(2, "0")
        : "",
      workOffStartHour: workOffStart
        ? String(
            workOffStart.diff(baseDate, "days").days < 1
              ? workOffStart.hour
              : workOffStart.hour + 24
          ).padStart(2, "0")
        : "",
      workOffStartMinute: workOffStart
        ? String(workOffStart.minute).padStart(2, "0")
        : "",
      workOffEndHour: workOffEnd
        ? String(
            workOffEnd.diff(baseDate, "days").days < 1
              ? workOffEnd.hour
              : workOffEnd.hour + 24
          ).padStart(2, "0")
        : "",
      workOffEndMinute: workOffEnd
        ? String(workOffEnd.minute).padStart(2, "0")
        : "",
      status: castShift.status,
      staffMemo: castShift.staffMemo,
      castMemo: castShift.castMemo,
      scheduleItemColor: castShift.scheduleItemColor,
      isForceShift: castShift.isForceShift,
    });
  }, [spCastShiftModal.meta?.castName]);

  const onChange =
    (key: string, handler: (value: any) => any = (value: any) => value) =>
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | { value: unknown }
      >
    ) => {
      event.persist();
      setCastShiftReq((prev: any) => ({
        ...prev,
        [key]: handler(event.target.value),
      }));
    };

  const onClickSubmit = async () => {
    if (
      !(
        castShiftReq.planWorkStartHour &&
        castShiftReq.planWorkStartMinute &&
        castShiftReq.planWorkEndHour &&
        castShiftReq.planWorkEndMinute
      )
    ) {
      return alert("予定勤務開始と予定勤務終了は必須項目です。");
    }
    const planWorkStart = DateTime.fromJSDate(
      spCastShiftModal.meta?.date || new Date()
    )
      .startOf("days")
      .plus({
        hours: Number(castShiftReq.planWorkStartHour),
        minutes: Number(castShiftReq.planWorkStartMinute),
      })
      .toJSDate();
    const planWorkEnd = DateTime.fromJSDate(
      spCastShiftModal.meta?.date || new Date()
    )
      .startOf("days")
      .plus({
        hours: Number(castShiftReq.planWorkEndHour),
        minutes: Number(castShiftReq.planWorkEndMinute),
      })
      .toJSDate();

    const actualWorkStart =
      castShiftReq.actualWorkStartHour && castShiftReq.actualWorkStartMinute
        ? DateTime.fromJSDate(spCastShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(castShiftReq.actualWorkStartHour),
              minutes: Number(castShiftReq.actualWorkStartMinute),
            })
            .toJSDate()
        : null;
    const actualWorkEnd =
      castShiftReq.actualWorkEndHour && castShiftReq.actualWorkEndMinute
        ? DateTime.fromJSDate(spCastShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(castShiftReq.actualWorkEndHour),
              minutes: Number(castShiftReq.actualWorkEndMinute),
            })
            .toJSDate()
        : null;

    const workOffStart =
      castShiftReq.workOffStartHour && castShiftReq.workOffStartMinute
        ? DateTime.fromJSDate(spCastShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(castShiftReq.workOffStartHour),
              minutes: Number(castShiftReq.workOffStartMinute),
            })
            .toJSDate()
        : null;
    const workOffEnd =
      castShiftReq.workOffEndHour && castShiftReq.workOffEndMinute
        ? DateTime.fromJSDate(spCastShiftModal.meta?.date || new Date())
            .startOf("days")
            .plus({
              hours: Number(castShiftReq.workOffEndHour),
              minutes: Number(castShiftReq.workOffEndMinute),
            })
            .toJSDate()
        : null;
    if (!spCastShiftModal.meta.castName.cast.shops) return;
    if (!spCastShiftModal.meta?.castName?.cast?.castShifts?.length) {
      const req = new CreateCastShiftReq({
        castId: spCastShiftModal.meta.castName.castId,
        shops: spCastShiftModal.meta.castName.cast.shops.map(
          (shop: any) => shop.shopId
        ),
        planWorkStart,
        planWorkEnd,
        actualWorkStart,
        actualWorkEnd,
        workOffStart,
        workOffEnd,
        status: castShiftReq.status,
        staffMemo: castShiftReq.staffMemo,
        castMemo: castShiftReq.castMemo,
        scheduleItemColor: castShiftReq.scheduleItemColor,
        isForceShift: castShiftReq.isForceShift,
      });
      await CastShiftApi.create(companyId, req);
    } else {
      const req = new UpdateCastShiftReq({
        castShiftId:
          spCastShiftModal.meta.castName.cast.castShifts[0].castShiftId,
        castId: spCastShiftModal.meta.castName.castId,
        shops: spCastShiftModal.meta.castName.cast.shops.map(
          (shop: any) => shop.shopId
        ),
        planWorkStart,
        planWorkEnd,
        actualWorkStart,
        actualWorkEnd,
        workOffStart,
        workOffEnd,
        status: castShiftReq.status,
        staffMemo: castShiftReq.staffMemo,
        castMemo: castShiftReq.castMemo,
        scheduleItemColor: castShiftReq.scheduleItemColor,
        isForceShift: castShiftReq.isForceShift,
      });
      await CastShiftApi.update(companyId, req);
    }
    apiCall();
    setSpCastShiftModal(false, {});
  };

  return (
    <Modal show={spCastShiftModal.show} setShow={setSpCastShiftModal}>
      <Box display="flex">
        <Typography>
          {DateTime.fromJSDate(
            spCastShiftModal.meta?.date || new Date()
          ).toFormat(FORMAT_TYPE.YEAR_DAY_JP)}
        </Typography>
        <Typography style={{ marginLeft: "8px" }}>
          {spCastShiftModal.meta?.castName?.name || ""}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>予定勤務開始</Label>
        <Box display="flex">
          <SelectTime
            castShiftReq={castShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"planWorkStartHour"}
            minuteKey={"planWorkStartMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>予定勤務終了</Label>
        <Box display="flex">
          <SelectTime
            castShiftReq={castShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"planWorkEndHour"}
            minuteKey={"planWorkEndMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>実勤務開始</Label>
        <Box display="flex">
          <SelectTime
            castShiftReq={castShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"actualWorkStartHour"}
            minuteKey={"actualWorkStartMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>実勤務終了</Label>
        <Box display="flex">
          <SelectTime
            castShiftReq={castShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"actualWorkEndHour"}
            minuteKey={"actualWorkEndMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>休憩開始</Label>
        <Box display="flex">
          <SelectTime
            castShiftReq={castShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"workOffStartHour"}
            minuteKey={"workOffStartMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>休憩終了</Label>
        <Box display="flex">
          <SelectTime
            castShiftReq={castShiftReq}
            onChange={onChange}
            hours={hours}
            minutes={minutes}
            hourKey={"workOffEndHour"}
            minuteKey={"workOffEndMinute"}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>ステータス</Label>
        <Box display="flex">
          <Select
            autoWidth
            style={{ minWidth: "100px" }}
            value={castShiftReq?.status || ""}
            onChange={onChange("status")}
          >
            {Object.entries(ShiftStatus).map(([key, name]) => (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>スタッフメモ</Label>
        <Box display="flex">
          <TextareaAutosize
            value={castShiftReq?.staffMemo || ""}
            onChange={onChange("staffMemo")}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>キャストメモ</Label>
        <Box display="flex">
          <TextareaAutosize
            value={castShiftReq?.castMemo || ""}
            onChange={onChange("castMemo")}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>スケジュールカラー</Label>
        <Box display="flex">
          <Select
            autoWidth
            style={{ minWidth: "100px" }}
            value={castShiftReq?.scheduleItemColor || ""}
            onChange={onChange("scheduleItemColor")}
          >
            {Object.entries(CastScheduleItemColor).map(([key, name]) => (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Label>営業時間無視</Label>
        <Box display="flex">
          <RadioGroup
            row
            aria-label="isForceShift"
            name="isForceShift"
            onChange={onChange("isForceShift")}
            value={castShiftReq?.isForceShift}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={"オン"}
              checked={castShiftReq?.isForceShift}
            />
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={"オフ"}
              checked={!castShiftReq?.isForceShift}
            />
          </RadioGroup>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" marginTop={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => onClickSubmit()}
        >
          送信
        </Button>
      </Box>
    </Modal>
  );
};

type SelectTimeProps = {
  hours: string[];
  minutes: string[];
  castShiftReq: any;
  onChange: any;
  hourKey: string;
  minuteKey: string;
};

const SelectTime = ({
  hours,
  minutes,
  hourKey,
  minuteKey,
  castShiftReq,
  onChange,
}: SelectTimeProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Select
        native
        style={{ minWidth: "50px" }}
        value={castShiftReq?.[hourKey] || ""}
        onChange={onChange(hourKey)}
      >
        <option value={""} />
        {hours.map((hour: string) => (
          <option key={hour} value={hour}>
            {hour}
          </option>
        ))}
      </Select>
      :
      <Select
        native
        style={{ minWidth: "50px" }}
        value={castShiftReq?.[minuteKey] || ""}
        onChange={onChange(minuteKey)}
      >
        <option value={""} />
        {minutes.map((minute: string) => (
          <option key={minute} value={minute}>
            {minute}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default SpCastShiftModal;
