import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import CastShiftRes from "types/res/castShift/CastShiftRes";
import {
  addCastShift,
  deleteCastShift,
  fetchCastShift,
  fetchCastShiftWithPagination,
  updateCastShift,
} from "redux/actions/castShift";
import { FormType } from "components/FormField";
import { fetchShops } from "redux/actions/shop";
import ShiftStatus from "types/enum/ShiftStatus";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Button,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CreateCastShiftReq from "types/req/castShift/CreateCastShiftReq";
import UpdateCastShiftReq from "types/req/castShift/UpdateCastShiftReq";
import EnumUtils from "utils/EnumUtils";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { Autocomplete } from "@material-ui/lab";
import { fetchCastCategory } from "redux/actions/castCategory";
import castShiftFilterActions from "redux/actions/castShiftFilter";
import { CastScheduleItemColor } from "types/enum/CastScheduleItemColor";
import CastApi from "api/CastApi";
import CastRes from "types/res/cast/CastRes";
import CastShiftApi from "api/CastShiftApi";

const CastShift: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const castShifts = useSelector((state) => state.castShift);
  const castCategories = useSelector((state) => state.castCategory);
  const castShiftFilter = useSelector((state) => state.castShiftFilter);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [casts, setCasts] = useState<CastRes[]>([]);
  const [filterCastShifts, setFilterCastShifts] = useState<CastShiftRes[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(100);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    dispatch(fetchShops(companyId));
    const apiCall = async () => {
      const result = await fetchCastCategory(companyId)(dispatch);
      if (result) {
        const castCategoryId = result.find((r) =>
          r.name.includes("在籍")
        )?.castCategoryId;
        const castRes = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: 0,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
        });
        setCasts(castRes);
      }
    };
    apiCall();
  }, [companyId, dispatch]);

  useEffect(() => {
    setIsLoading(true);
    const apiCall = async () => {
      await dispatch(
        fetchCastShiftWithPagination(
          companyId,
          DateTimeUtils.toFormatAsLocalTimezone(
            startDate,
            FORMAT_TYPE.YEAR_DAY
          ),
          DateTimeUtils.toFormatAsLocalTimezone(endDate, FORMAT_TYPE.YEAR_DAY),
          limit,
          offset
        )
      );
      const result = await CastShiftApi.count(
        companyId,
        DateTimeUtils.toFormatAsLocalTimezone(startDate, FORMAT_TYPE.YEAR_DAY),
        DateTimeUtils.toFormatAsLocalTimezone(endDate, FORMAT_TYPE.YEAR_DAY)
      );
      setTotal(result);
      setIsLoading(false);
    };
    apiCall();
  }, [companyId, dispatch, startDate, endDate, limit, offset]);

  useEffect(() => {
    dispatch(
      castShiftFilterActions.setCastShiftFilter({
        selectShopIds: [...shops.map((shop) => String(shop.shopId)), "すべて"],
        selectCasts: castShifts.map((castShift) => castShift.cast),
        selectCastCategoryIds: castCategories
          .filter((castCategory) => castCategory.name.includes("在籍"))
          .map((castCategory) => String(castCategory.castCategoryId)),
      })
    );
  }, [shops, castShifts, castCategories]);

  useEffect(() => {
    const filter = castShifts.filter((castShift) => {
      const castCondition = castShiftFilter.selectCasts.some(
        (selectCast) => selectCast.castId === castShift.castId
      );
      const shopCondition =
        castShift?.shops?.length &&
        castShift.shops.some((shop) =>
          castShiftFilter.selectShopIds.includes(String(shop.shopId))
        );
      return castCondition && shopCondition;
    });
    setFilterCastShifts(filter);
  }, [castShifts, castShiftFilter]);

  const headers: { key: keyof CastShiftRes; label: string }[] = [
    { key: "cast", label: "キャスト名" },
    { key: "cast", label: "キャスト電話番号" },
    { key: "castShiftId", label: "源氏名" },
    { key: "shops", label: "店舗名" },
    { key: "planWorkStart", label: "出勤予定" },
    { key: "actualWorkStart", label: "実出勤" },
    { key: "workOffStart", label: "休憩" },
    { key: "status", label: "ステータス" },
    { key: "staffMemo", label: "スタッフメモ" },
    { key: "castMemo", label: "キャストメモ" },
    { key: "scheduleItemColor", label: "スケジュールカラー" },
  ];
  const forms = [
    {
      label: "キャスト名",
      key: "castId",
      type: FormType.AutoCompleteSelect,
      options: casts.map((c) => ({ id: c.castId, name: c.displayName })),
      loadMoreResults: async (displayName?: string) => {
        const offset = Math.trunc(casts.length / 100);
        const castCategoryId = castCategories.find((castCategory) =>
          castCategory.name.includes("在籍")
        )?.castCategoryId;
        const c = await CastApi.findAllWithPagination(companyId, {
          limit: 100,
          offset: displayName ? 0 : offset,
          castCategoryIds: castCategoryId ? [castCategoryId] : undefined,
          displayName,
        });
        setCasts((prev) =>
          Array.from(
            new Map([...prev, ...c].map((cast) => [cast.castId, cast])).values()
          )
        );
      },
    },
    {
      label: "店舗名",
      key: "shops",
      type: FormType.MultiOptions,
      options: shops.map((s) => ({ id: s.shopId, name: s.name })),
      value: (val: any) => val.map((s: any) => s.shopId),
    },
    {
      label: "予定勤務開始",
      key: "planWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "予定勤務終了",
      key: "planWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "実勤務開始",
      key: "actualWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "実勤務終了",
      key: "actualWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩開始",
      key: "workOffStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩終了",
      key: "workOffEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "ステータス",
      key: "status",
      type: FormType.Select,
      options: Object.entries(ShiftStatus).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "スタッフメモ",
      key: "staffMemo",
      type: FormType.Text,
    },
    {
      label: "キャストメモ",
      key: "castMemo",
      type: FormType.Text,
    },
    {
      label: "スケジュールカラー",
      key: "scheduleItemColor",
      type: FormType.Select,
      options: Object.entries(CastScheduleItemColor).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "営業時間無視",
      key: "isForceShift",
      type: FormType.Boolean,
      options: [
        { id: "0", name: "オフ" },
        { id: "1", name: "オン" },
      ],
    },
  ];

  const selectShopIds = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (
      castShiftFilter.selectShopIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [];
    } else if (
      !castShiftFilter.selectShopIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") !== -1
    ) {
      return [...shops.map((shop) => String(shop.shopId)), "すべて"];
    } else if (
      (event.target.value as string[]).length === shops.length &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [...shops.map((shop) => String(shop.shopId)), "すべて"];
    } else if ((event.target.value as string[]).length <= shops.length) {
      return (event.target.value as string[]).filter(
        (name) => name !== "すべて"
      );
    } else {
      return event.target.value as string[];
    }
  };

  const selectCastCategoryIds = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (
      castShiftFilter.selectCastCategoryIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [];
    } else if (
      !castShiftFilter.selectCastCategoryIds.includes("すべて") &&
      (event.target.value as string[]).indexOf("すべて") !== -1
    ) {
      return [
        ...castCategories.map((castCategory) =>
          String(castCategory.castCategoryId)
        ),
        "すべて",
      ];
    } else if (
      (event.target.value as string[]).length === castCategories.length &&
      (event.target.value as string[]).indexOf("すべて") === -1
    ) {
      return [
        ...castCategories.map((castCategory) =>
          String(castCategory.castCategoryId)
        ),
        "すべて",
      ];
    } else if (
      (event.target.value as string[]).length <= castCategories.length
    ) {
      return (event.target.value as string[]).filter(
        (name) => name !== "すべて"
      );
    } else {
      return event.target.value as string[];
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex">
          <TextField
            type="date"
            label="開始日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(startDate).toFormat(
              FORMAT_TYPE.YEAR_DAY
            )}
            onChange={(event) =>
              setStartDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <Box marginTop={"10px"}>
            <InputLabel id="select-multiple-shop">店舗</InputLabel>
            <Select
              multiple
              value={castShiftFilter.selectShopIds}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                dispatch(
                  castShiftFilterActions.setCastShiftFilter({
                    selectShopIds: selectShopIds(event),
                  })
                );
              }}
              input={<Input id="select-multiple-shop" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return shops
                    .filter((shop) =>
                      (selected as string[]).includes(String(shop.shopId))
                    )
                    .map((shop) => shop.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={castShiftFilter.selectShopIds.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {shops.map((shop) => (
                <MenuItem key={shop.shopId} value={String(shop.shopId)}>
                  <Checkbox
                    checked={
                      castShiftFilter.selectShopIds.indexOf(
                        String(shop.shopId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={shop.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <Autocomplete
              multiple
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField {...params} label="キャスト名" />
              )}
              getOptionLabel={(cast) => cast.displayName}
              options={casts}
              filterOptions={(options, state) =>
                options.filter((option) =>
                  option.displayName.includes(state.inputValue)
                )
              }
              onInputChange={async (event, value) => {
                event.persist();
                const c = await CastApi.findAllWithPagination(companyId, {
                  limit: 100,
                  offset: 0,
                  displayName: value,
                  castCategoryIds:
                    castShiftFilter.selectCastCategoryIds.includes("すべて") ||
                    castShiftFilter.selectCastCategoryIds.length === 0
                      ? undefined
                      : castShiftFilter.selectCastCategoryIds.map(Number),
                });
                setCasts((prev) =>
                  Array.from(
                    new Map(
                      [...prev, ...c].map((cast) => [cast.castId, cast])
                    ).values()
                  )
                );
              }}
              style={{
                width: "400px",
                marginRight: "10px",
              }}
              value={castShiftFilter.selectCasts}
              limitTags={2}
              getLimitTagsText={(more) => {
                return <Typography>+{more}</Typography>;
              }}
              ListboxProps={{
                onScroll: async (event: React.SyntheticEvent) => {
                  const listBoxNode = event.currentTarget;
                  if (
                    listBoxNode.scrollTop + listBoxNode.clientHeight >
                    listBoxNode.scrollHeight - 1
                  ) {
                    const offset = Math.trunc(casts.length / 100);
                    const c = await CastApi.findAllWithPagination(companyId, {
                      limit: 100,
                      offset,
                      castCategoryIds:
                        castShiftFilter.selectCastCategoryIds.includes(
                          "すべて"
                        ) || castShiftFilter.selectCastCategoryIds.length === 0
                          ? undefined
                          : castShiftFilter.selectCastCategoryIds.map(Number),
                    });
                    setCasts((prev) =>
                      Array.from(
                        new Map(
                          [...prev, ...c].map((cast) => [cast.castId, cast])
                        ).values()
                      )
                    );
                  }
                },
              }}
              onChange={(event, value) =>
                dispatch(
                  castShiftFilterActions.setCastShiftFilter({
                    selectCasts: value,
                  })
                )
              }
            />
          </Box>
          <Box marginTop={"10px"}>
            <InputLabel id="select-multiple-class">キャスト区分</InputLabel>
            <Select
              multiple
              value={castShiftFilter.selectCastCategoryIds}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                dispatch(
                  castShiftFilterActions.setCastShiftFilter({
                    selectCastCategoryIds: selectCastCategoryIds(event),
                  })
                );
              }}
              input={<Input id="select-multiple-castCategory" />}
              style={{ width: "200px", marginRight: "10px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return castCategories
                    .filter((castCategory) =>
                      (selected as string[]).includes(
                        String(castCategory.castCategoryId)
                      )
                    )
                    .map((castCategory) => castCategory.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={
                    castShiftFilter.selectCastCategoryIds.indexOf("すべて") > -1
                  }
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {castCategories.map((castCategory) => (
                <MenuItem
                  key={castCategory.castCategoryId}
                  value={String(castCategory.castCategoryId)}
                >
                  <Checkbox
                    checked={
                      castShiftFilter.selectCastCategoryIds.indexOf(
                        String(castCategory.castCategoryId)
                      ) !== -1
                    }
                  />
                  <ListItemText primary={castCategory.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box marginTop={"10px"}>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              component={Link}
              to="/castShift/bulkInsert"
            >
              一括追加画面へ
            </Button>
          </Box>
        </Box>
      </Box>
      <CastShiftTable
        title={"キャスト出退勤"}
        formId="castShiftForm"
        forms={forms}
        isLoading={isLoading}
        addFunc={(formData) => addCastShift(companyId, formData)}
        updateHook={(formData) => {
          setCasts((prev) =>
            Array.from(
              new Map(
                [...prev, formData.cast].map((cast) => [cast.castId, cast])
              ).values()
            )
          );
        }}
        updateFunc={(formData) => updateCastShift(companyId, formData)}
        deleteFunc={(item) =>
          deleteCastShift(companyId, {
            castShiftId: item?.castShiftId,
          })
        }
        setLimit={setLimit}
        setOffset={setOffset}
        limit={limit}
        offset={offset}
        totalCount={total}
        rows={headers}
        data={filterCastShifts}
        addType={CreateCastShiftReq}
        updateType={UpdateCastShiftReq}
        defaultValue={{ status: "unconfirmed" }}
        values={[
          (c) => c.cast?.displayName || "未設定",
          (c) => c.cast?.tel || "未設定",
          (c) =>
            c.cast?.castNames.map((castName) => castName.name).join(",") ||
            "未設定",
          (c) => c.shops?.map((shop) => shop.name).join(",") || "未設定",
          (c) =>
            `${DateTimeUtils.toFormatAsLocalTimezone(
              c.planWorkStart,
              FORMAT_TYPE.YEAR_DATE_TIME
            )} ~ ${DateTimeUtils.toFormatAsLocalTimezone(
              c.planWorkEnd,
              FORMAT_TYPE.YEAR_DATE_TIME
            )}`,
          (c) =>
            `${
              c.actualWorkStart
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.actualWorkStart,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            } ~ ${
              c.actualWorkEnd
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.actualWorkEnd,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            }`,
          (c) =>
            `${
              c.workOffStart
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.workOffStart,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            } ~ ${
              c.workOffEnd
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.workOffEnd,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            }`,
          (c) => EnumUtils.mapToEnum(ShiftStatus, c.status) || "未設定",
          (c) => c.staffMemo || "",
          (c) => c.castMemo || "",
          (c) =>
            EnumUtils.mapToEnum(CastScheduleItemColor, c.scheduleItemColor) ||
            "未設定",
        ]}
      />
    </>
  );
};

const CastShiftTable = styled<
  React.FC<
    CommonTableProps<CastShiftRes, CreateCastShiftReq, UpdateCastShiftReq>
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default CastShift;
