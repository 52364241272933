import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableContainer,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import EnumUtils from "utils/EnumUtils";
import StaffRole from "types/enum/StaffRole";
import { S3 } from "aws-sdk";
import { simpleFetchGuest } from "redux/actions/guest";
import { fetchCastName } from "redux/actions/castName";
import { fetchCosplay } from "redux/actions/cosplay";
import { fetchCourse } from "redux/actions/course";
import { fetchNomination } from "redux/actions/nomination";
import { fetchNotelClass } from "redux/actions/notelClass";
import { fetchAdditionalTime } from "redux/actions/additionalTime";
import { fetchArea } from "redux/actions/area";
import { fetchHotel } from "redux/actions/hotel";
import { fetchDriver } from "redux/actions/driver";
import { fetchMedium } from "redux/actions/medium";
import { fetchOption } from "redux/actions/option";
import { fetchDiscount } from "redux/actions/discount";
import CsvUtils, { CsvFieldType } from "utils/CsvUtils";
import OrderStatus from "types/enum/OrderStatus";
import OrderResponseStatus from "types/enum/OrderResponseStatus";
import PaymentType from "types/enum/PaymentType";
import CreateOrderReq from "types/req/order/CreateOrderReq";
import { DateTime } from "luxon";
import { ValidationError } from "class-validator";
import CsvImportTable from "components/CsvImportTable";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import { fetchShops } from "redux/actions/shop";

const OrderBulkImportCheck = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const staff = useSelector((state) => state.account.staff);
  const [fileList, setFileList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const shops = useSelector((state) => state.shop);
  const guests = useSelector((state) => state.guest);
  const castNames = useSelector((state) => state.castName);
  const cosplays = useSelector((state) => state.cosplay);
  const courses = useSelector((state) => state.course);
  const notelClasses = useSelector((state) => state.notelClass);
  const nominations = useSelector((state) => state.nomination);
  const additionalTimes = useSelector((state) => state.additionalTime);
  const areas = useSelector((state) => state.area);
  const hotels = useSelector((state) => state.hotel);
  const drivers = useSelector((state) => state.driver);
  const media = useSelector((state) => state.medium);
  const discounts = useSelector((state) => state.discount);
  const options = useSelector((state) => state.option);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const [csvData, setCsvData] = useState<any[]>([]);
  const [isFinishDownload, setIsFinishDownload] = useState(false);
  const [bulkInsertErrors, setBulkInsertErrors] = useState<{
    [x: number]: ValidationError[];
  }>({});
  const [bulkInsertData, setBulkInsertData] = useState<
    { [key: string]: string | number | Date }[]
  >([]);
  const [sampleBulkInsertData, setSampleBulkInsertData] = useState<any>([]);
  const [isDetailCheck, setIsDetailCheck] = useState(false);
  const [page, setPage] = useState(0);

  const csvHeader = [
    {
      label: "※日付",
      key: "orderDate",
      type: CsvFieldType.Date,
    },
    {
      label: "※顧客(電話番号)",
      key: "guestId",
      type: CsvFieldType.Object,
      relation: guests,
      relationKey: "tel",
      relationId: "guestId",
    },
    {
      label: "※店舗",
      key: "shopId",
      type: CsvFieldType.Object,
      relation: shops,
      relationId: "shopId",
      relationKey: "name",
    },
    {
      label: "※源氏名",
      key: "castNameId",
      type: CsvFieldType.Object,
      relation: castNames,
      relationId: "castNameId",
      relationKey: "name",
      sub: true,
      subRelationKey: "shopId",
    },
    {
      label: "コスプレ",
      key: "cosplayId",
      type: CsvFieldType.Object,
      relation: cosplays,
      relationId: "cosplayId",
      relationKey: "name",
    },
    {
      label: "※コース",
      key: "courseId",
      type: CsvFieldType.Object,
      relation: courses,
      relationId: "courseId",
      relationKey: "name",
    },
    {
      label: "※指名種",
      key: "nominationId",
      type: CsvFieldType.Object,
      relation: nominations,
      relationId: "nominationId",
      relationKey: "name",
    },
    {
      label: "※クラス",
      key: "notelClassId",
      type: CsvFieldType.Object,
      relation: notelClasses,
      relationId: "notelClassId",
      relationKey: "name",
    },
    {
      label: "延長",
      key: "additionalTimeId",
      type: CsvFieldType.Object,
      relation: additionalTimes,
      relationId: "additionalTimeId",
      relationKey: "time",
    },
    {
      label: "延長回数",
      key: "additionalTimeCount",
      type: CsvFieldType.Number,
    },
    {
      label: "※エリア",
      key: "areaId",
      type: CsvFieldType.Object,
      relation: areas,
      relationId: "areaId",
      relationKey: "name",
    },
    {
      label: "ホテル",
      key: "hotelId",
      type: CsvFieldType.Object,
      relation: hotels,
      relationId: "hotelId",
      relationKey: "name",
    },
    {
      label: "迎えドライバー",
      key: "outwardDriverId",
      type: CsvFieldType.Object,
      relation: drivers,
      relationId: "outwardDriverId",
      relationKey: "name",
    },
    {
      label: "送りドライバー",
      key: "returnDriverId",
      type: CsvFieldType.Object,
      relation: drivers,
      relationId: "returnDriverId",
      relationKey: "name",
    },
    {
      label: "媒体",
      key: "mediumId",
      type: CsvFieldType.Object,
      relation: media,
      relationId: "mediumId",
      relationKey: "name",
    },
    {
      label: "※ステータス",
      key: "status",
      type: CsvFieldType.Enum,
      enumObject: OrderStatus,
    },
    {
      label: "※出発時間",
      key: "departureTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※予定IN時間",
      key: "planInTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※予定OUT時間",
      key: "planOutTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※実IN時間",
      key: "actualInTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※実OUT時間",
      key: "actualEndTime",
      type: CsvFieldType.DateTime,
    },
    {
      label: "※応答",
      key: "responseStatus",
      type: CsvFieldType.Enum,
      enumObject: OrderResponseStatus,
    },
    {
      label: "※回収",
      key: "collectReceivable",
      type: CsvFieldType.Boolean,
    },
    {
      label: "※精算",
      key: "payoff",
      type: CsvFieldType.Boolean,
    },
    {
      label: "※支払い",
      key: "paymentType",
      type: CsvFieldType.Enum,
      enumObject: PaymentType,
    },
    {
      label: "メモ",
      key: "memo",
      type: CsvFieldType.Text,
    },
    {
      label: "アンケート",
      key: "questionnaire",
      type: CsvFieldType.Text,
    },
    {
      label: "住所1",
      key: "orderAddress",
      type: CsvFieldType.Text,
    },
    {
      label: "※住所2",
      key: "orderAddress2",
      type: CsvFieldType.Text,
    },
    {
      label: "スコア※数字のみ",
      key: "score",
      type: CsvFieldType.Number,
    },
    {
      label: "オプション",
      key: "options",
      type: CsvFieldType.Array,
      relation: options,
      relationId: "optionId",
      relationKey: "name",
    },
    {
      label: "割引",
      key: "discounts",
      type: CsvFieldType.Array,
      relation: discounts,
      relationId: "discountId",
      relationKey: "name",
    },
    {
      label: "ポイント利用※数字のみ",
      key: "pointFee",
      type: CsvFieldType.Number,
    },
    {
      label: "カード利用料※数字のみ",
      key: "cardFee",
      type: CsvFieldType.Number,
    },
    {
      label: "その他料金※数字のみ",
      key: "otherFee",
      type: CsvFieldType.Number,
    },
    {
      label: "その他キャスト料金※数字のみ",
      key: "otherCastFee",
      type: CsvFieldType.Number,
    },
    {
      label: "その他店舗料金※数字のみ",
      key: "otherShopFee",
      type: CsvFieldType.Number,
    },
  ];

  useEffect(() => {
    dispatch(simpleFetchGuest(companyId));
    dispatch(fetchShops(companyId));
    dispatch(fetchCastName(companyId));
    dispatch(fetchCosplay(companyId));
    dispatch(fetchCourse(companyId));
    dispatch(fetchNomination(companyId));
    dispatch(fetchNotelClass(companyId));
    dispatch(fetchAdditionalTime(companyId));
    dispatch(fetchArea(companyId));
    dispatch(fetchHotel(companyId));
    dispatch(fetchDriver(companyId));
    dispatch(fetchMedium(companyId));
    dispatch(fetchOption(companyId));
    dispatch(fetchDiscount(companyId));
  }, [companyId]);

  useEffect(() => {
    if (!isFinishDownload) return;
    csvData.map(async (rowData) => {
      console.log("parseStart");
      const { bulkInsertReq, displayDataArray } =
        await CsvUtils.createBulkInsertData(
          csvHeader,
          rowData,
          CreateOrderReq,
          undefined,
          DateTime.fromFormat(changeDateTime, "HH:mm:ss").hour
        );
      const errors: { [x: number]: ValidationError[] } = {};
      const sliceData = displayDataArray.slice(0, 10);
      bulkInsertReq.forEach((data: { [key: string]: any }, index) => {
        if (Array.isArray(data)) {
          errors[index] = data;
        }
      });
      setBulkInsertErrors(errors);
      setBulkInsertData(displayDataArray);
      setSampleBulkInsertData(sliceData);
    });
    setIsLoading(false);
  }, [isFinishDownload]);

  const s3Client = new S3({
    region: "ap-northeast-1",
    credentials: {
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID || "",
      secretAccessKey: process.env.REACT_APP_S3_ACCESS_KEY_SECRET || "",
    },
  });

  const onClickDownload = async () => {
    await s3Client.listObjects(
      {
        Bucket: "notel-order-bulk-import",
      },
      (err, data) => {
        if (err) {
          alert(err);
        } else {
          if (data.Contents) {
            setFileList(
              data.Contents.map((content) => {
                return content.Key || "";
              })
            );
          }
        }
      }
    );
  };

  const onClickFileCheck = async () => {
    setIsLoading(true);
    const filePromise = fileList.map(async (file) => {
      const csvFile = await s3Client
        .getObject({
          Bucket: "notel-order-bulk-import",
          Key: file,
        })
        .promise();
      const buf = new Uint8Array(csvFile.Body as Uint8Array).buffer;
      const text = new TextDecoder().decode(buf);
      const dataArray = text.replace(/"/g, "").split("\r\n");
      setCsvData((prev: any) => [
        ...prev,
        dataArray.flatMap((d) => ({
          data: d.split(","),
        })),
      ]);
    });
    await Promise.all(filePromise);
    setIsFinishDownload(true);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage(0);
  };
  if (EnumUtils.mapToEnum(StaffRole, staff.role) !== StaffRole.notelMaster)
    return null;

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box display={"flex"} margin={2}>
        <Button variant={"outlined"} onClick={() => onClickDownload()}>
          ファイル一覧取得
        </Button>
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        <Box display={"flex"} margin={2}>
          <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
            対象ファイル一覧
          </Typography>
          <Button
            variant={"outlined"}
            style={{ marginLeft: "10px" }}
            onClick={() => onClickFileCheck()}
          >
            全ファイルチェック
          </Button>
        </Box>
        {isLoading ? (
          <CircularProgress />
        ) : (
          fileList.map((file) => (
            <Box key={file} display={"flex"}>
              <Typography>{file}</Typography>
            </Box>
          ))
        )}
      </Box>
      {sampleBulkInsertData.length > 0 && (
        <Box marginY={2}>
          <CsvImportTable
            csvHeader={csvHeader}
            csvData={sampleBulkInsertData}
            bulkInsertError={bulkInsertErrors}
          />
        </Box>
      )}
      {Object.keys(bulkInsertErrors).length === 0 ? (
        <></>
      ) : (
        <Box display="flex" flexDirection="column">
          <Typography>
            エラーがあります。csvファイルを修正して再アップしてください。
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            style={{ maxHeight: "200px", overflowY: "scroll" }}
          >
            {Object.entries(bulkInsertErrors).flatMap(([index, errors]) =>
              errors.flatMap((error) => (
                <Box display="flex" alignItems="center">
                  <Box
                    style={{
                      backgroundColor: "#ff8888",
                      width: "10px",
                      height: "10px",
                      marginRight: "5px",
                    }}
                  />
                  <Typography>
                    {Number(index) + 1}行目の
                    {csvHeader.find((header) => header.key === error.property)
                      ?.label || ""}
                    {error.property === "tel" &&
                    error?.value === "重複しています。"
                      ? "が重複しています。"
                      : "を修正してください"}
                  </Typography>
                </Box>
              ))
            )}
          </Box>
          <Button
            onClick={() => {
              setCsvData([]);
              setBulkInsertErrors({});
            }}
            variant="contained"
            color="default"
          >
            データクリア
          </Button>
        </Box>
      )}
      {bulkInsertData.length > 0 && (
        <Box>
          <Button variant={"outlined"} onClick={() => setIsDetailCheck(true)}>
            詳細データ確認
          </Button>
        </Box>
      )}
      {isDetailCheck && bulkInsertData.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table style={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  {csvHeader.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        width: `${100 / csvHeader.length}%`,
                        padding: "0 2px",
                      }}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {bulkInsertData
                  .slice(page * 1000, page * 1000 + 1000)
                  .map((data, index) => {
                    return (
                      <TableRow key={index}>
                        {Object.entries(data).map(([key, value]) => {
                          if (
                            csvHeader.map((header) => header.key).includes(key)
                          ) {
                            return (
                              <TableCell
                                style={{
                                  width: `${100 / csvHeader.length}%`,
                                  padding: "0 2px",
                                  backgroundColor: bulkInsertErrors[
                                    index + page * 1000
                                  ]?.some((error) => error.property === key)
                                    ? "#ff8888"
                                    : "init",
                                }}
                              >
                                {Array.isArray(value)
                                  ? value.join(" ")
                                  : value instanceof Date
                                  ? DateTime.fromJSDate(value as Date).toFormat(
                                      FORMAT_TYPE.YEAR_DATE_TIME
                                    )
                                  : (value as string)}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[1000]}
            component="div"
            count={bulkInsertData.length}
            rowsPerPage={1000}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="ページ毎件数"
          />
        </>
      )}
    </Box>
  );
};
export default OrderBulkImportCheck;
