import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { fetchShops } from "redux/actions/shop";
import { DateTime } from "luxon";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { FORMAT_TYPE } from "utils/DateTimeUtils";
import CastNameApi from "api/CastNameApi";
import CastNameRes from "types/res/castName/CastNameRes";
import useModal from "hooks/useModal";
import SpCastShiftModal from "spPages/CastShift/SpCastShiftModal";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
const SpCastShift = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const shops = useSelector((state) => state.shop);
  const [selectShopId, setSelectShopId] = useState<number>();
  const todayObject = DateTime.local().startOf("day");
  const [dateList, setDateList] = useState<Date[]>(
    [...Array(7)].map((_, i) => todayObject.plus({ day: i }).toJSDate())
  );
  const [selectDate, setSelectDate] = useState(todayObject.toJSDate());
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [castNames, setCastNames] = useState<CastNameRes[]>([]);
  const [spCastShiftModal, setSpCastShiftModal] = useModal("spCastShiftModal");

  useEffect(() => {
    dispatch(fetchShops(companyId));
  }, [companyId]);

  useEffect(() => {
    if (!shops.length) return;
    setSelectShopId(shops[0].shopId);
  }, [shops]);

  useEffect(() => {
    if (!selectShopId) return;
    const apiCall = async () => {
      const result = await CastNameApi.spFind(
        companyId,
        selectShopId,
        DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY),
        15,
        offset
      );
      setCastNames(result);
    };
    apiCall();
  }, [selectShopId, selectDate, offset]);

  useEffect(() => {
    if (!selectShopId) return;
    const apiCountCall = async () => {
      const result = await CastNameApi.spCount(companyId, selectShopId);
      setTotalCount(result);
    };
    apiCountCall();
  }, [selectShopId]);

  const onClickBackDay = () => {
    const startDate = dateList[0];
    setDateList(
      [...Array(7)].map((_, i) =>
        DateTime.fromJSDate(startDate)
          .minus({ day: 7 })
          .plus({ day: i })
          .toJSDate()
      )
    );
  };

  const onClickNextDay = () => {
    const endDate = dateList[6];
    setDateList(
      [...Array(7)].map((_, i) =>
        DateTime.fromJSDate(endDate).plus({ day: i }).toJSDate()
      )
    );
  };

  const onClickSelectDay = (date: Date) => {
    setSelectDate(date);
  };

  const apiCall = async () => {
    if (!selectShopId) return;
    const result = await CastNameApi.spFind(
      companyId,
      selectShopId,
      DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY),
      15,
      offset
    );
    setCastNames(result);
  };

  const dateString = (date: Date) => {
    const baseDate = DateTime.fromJSDate(selectDate);
    const targetDate = DateTime.fromJSDate(date);
    return `${String(
      targetDate.diff(baseDate, "days").days < 1
        ? targetDate.hour
        : targetDate.hour + 24
    ).padStart(2, "0")}:${String(targetDate.minute).padStart(2, "0")}`;
  };

  const prevCastNames = () => {
    if (offset === 0) return;
    setOffset((prev) => prev - 1);
  };

  const nextCastNames = () => {
    if ((offset + 1) * 15 > totalCount) return;
    setOffset((prev) => prev + 1);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        style={{
          position: "sticky",
          top: "56px",
          left: "0",
          backgroundColor: "white",
          zIndex: "1000",
        }}
      >
        <Box display="flex" flexDirection="column" borderBottom={1} padding={2}>
          <Select
            value={selectShopId || ""}
            onChange={(event) => setSelectShopId(Number(event.target.value))}
          >
            {shops.map((shop) => (
              <MenuItem value={shop.shopId} key={shop.shopId}>
                <Typography>{shop.name}</Typography>
              </MenuItem>
            ))}
          </Select>
          <Typography
            style={{
              textAlign: "center",
              marginTop: "8px",
              fontSize: "18px",
            }}
          >
            週間スケジュール
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginTop={1}
        >
          <Box onClick={onClickBackDay}>
            <ChevronLeftIcon />
          </Box>
          {dateList.map((date, index) => (
            <Box
              display="flex"
              flexDirection="column"
              key={index}
              alignItems="center"
              onClick={() => onClickSelectDay(date)}
            >
              <Typography>
                {DateTime.fromJSDate(date).toFormat("ccc")}
              </Typography>
              <Typography>{DateTime.fromJSDate(date).toFormat("d")}</Typography>
            </Box>
          ))}
          <Box onClick={onClickNextDay}>
            <ChevronRightIcon />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography>
            {DateTime.fromJSDate(selectDate).toFormat(FORMAT_TYPE.YEAR_DAY_JP)}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{ backgroundColor: "#dcdcdc" }}
        padding={1}
      >
        {castNames.map((castName) => (
          <Box
            key={castName.castNameId}
            style={{
              backgroundColor: "white",
              padding: "8px",
              borderBottom: "1px solid #e1dede",
            }}
            display="flex"
            onClick={() =>
              setSpCastShiftModal(true, {
                date: selectDate,
                shopId: selectShopId,
                castName: castName,
              })
            }
          >
            <Typography style={{ width: "50%" }}>{castName.name}</Typography>
            <Typography
              style={{
                color: !castName.cast.castShifts.length ? "#e1dede" : "black",
              }}
            >
              {!castName.cast.castShifts.length
                ? "未設定"
                : `${dateString(
                    castName.cast.castShifts[0].actualWorkStart ||
                      castName.cast.castShifts[0].planWorkStart
                  )} ~ ${dateString(
                    castName.cast.castShifts[0].actualWorkEnd ||
                      castName.cast.castShifts[0].planWorkEnd
                  )}`}
            </Typography>
          </Box>
        ))}
      </Box>
      {spCastShiftModal.show && <SpCastShiftModal apiCall={apiCall} />}
      <Box display="flex" justifyContent="space-between">
        <IconButton onClick={prevCastNames}>
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={nextCastNames}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
export default SpCastShift;
