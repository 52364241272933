import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CommonTable, { CommonTableProps } from "components/CommonTable";
import DriverShiftRes from "types/res/driverShift/DriverShiftRes";
import {
  addDriverShift,
  deleteDriverShift,
  fetchDriverShift,
  updateDriverShift,
} from "redux/actions/driverShift";
import { fetchDriver } from "redux/actions/driver";
import { FormType } from "components/FormField";
import ShiftStatus from "types/enum/ShiftStatus";
import { DateTime } from "luxon";
import DateTimeUtils, { FORMAT_TYPE } from "utils/DateTimeUtils";
import {
  Button,
  createStyles,
  InputLabel,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CreateDriverShiftReq from "types/req/driverShift/CreateDriverShiftReq";
import UpdateDriverShiftReq from "types/req/driverShift/UpdateDriverShiftReq";
import EnumUtils from "../../utils/EnumUtils";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { CastScheduleItemColor } from "types/enum/CastScheduleItemColor";

const DriverShift: React.FC = () => {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.account.staff.companyId);
  const drivers = useSelector((state) => state.driver);
  const driverShifts = useSelector((state) => state.driverShift);
  const changeDateTime = useSelector(
    (state) => state.account.staff.company.changeDateTime
  );
  const changeDate = DateTime.fromFormat(changeDateTime, "HH:mm:ss");
  const [startDate, setStartDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(
    DateTime.local()
      .minus({ hours: changeDate.hour, minutes: changeDate.minute })
      .toJSDate()
  );
  const [selectDrivers, setSelectDrivers] = useState<string[]>([]);
  const [filterDriverShifts, setFilterDriverShifts] = useState<
    DriverShiftRes[]
  >([]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
    })
  );
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchDriver(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(
      fetchDriverShift(
        companyId,
        DateTimeUtils.toFormatAsLocalTimezone(startDate, FORMAT_TYPE.YEAR_DAY),
        DateTimeUtils.toFormatAsLocalTimezone(endDate, FORMAT_TYPE.YEAR_DAY)
      )
    );
  }, [companyId, dispatch, startDate, endDate]);

  useEffect(() => {
    setSelectDrivers([
      ...drivers.map((driver) => String(driver.driverId)),
      "すべて",
    ]);
  }, [drivers]);

  useEffect(() => {
    setFilterDriverShifts(
      driverShifts.filter((driverShift) =>
        selectDrivers.includes(String(driverShift.driverId))
      )
    );
  }, [selectDrivers, driverShifts]);

  useEffect(() => {
    setFilterDriverShifts(driverShifts);
  }, [driverShifts]);
  const headers: { key: keyof DriverShiftRes; label: string }[] = [
    { key: "driver", label: "ドライバー名" },
    { key: "driver", label: "ドライバー電話番号" },
    { key: "planWorkStart", label: "出勤予定" },
    { key: "actualWorkStart", label: "実出勤" },
    { key: "workOffStart", label: "休憩" },
    { key: "status", label: "ステータス" },
    { key: "driver", label: "車両情報" },
    { key: "driver", label: "スタッフメモ" },
    { key: "driverMemo", label: "ドライバーメモ" },
    { key: "scheduleItemColor", label: "スケジュールカラー" },
  ];

  const forms = [
    {
      label: "ドライバー名",
      key: "driverId",
      type: FormType.Select,
      options: drivers.map((c) => ({ id: c.driverId, name: c.name })),
    },
    {
      label: "予定勤務開始",
      key: "planWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "予定勤務終了",
      key: "planWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 15,
    },
    {
      label: "実勤務開始",
      key: "actualWorkStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "実勤務終了",
      key: "actualWorkEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩開始",
      key: "workOffStart",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "休憩終了",
      key: "workOffEnd",
      type: FormType.IntervalDateTime,
      minuteStep: 5,
    },
    {
      label: "ステータス",
      key: "status",
      type: FormType.Select,
      options: Object.entries(ShiftStatus).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "スケジュールカラー",
      key: "scheduleItemColor",
      type: FormType.Select,
      options: Object.entries(CastScheduleItemColor).map(([key, value]) => ({
        id: key,
        name: value,
      })),
    },
    {
      label: "スタッフメモ",
      key: "staffMemo",
      type: FormType.Text,
    },
    {
      label: "ドライバーメモ",
      key: "driverMemo",
      type: FormType.Text,
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box margin={2} display="flex" alignItems="center">
          <TextField
            type="date"
            label="開始日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(startDate).toFormat(
              FORMAT_TYPE.YEAR_DAY
            )}
            onChange={(event) =>
              setStartDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <TextField
            type="date"
            label="終了日"
            style={{ margin: "10px", width: "200px" }}
            value={DateTime.fromJSDate(endDate).toFormat(FORMAT_TYPE.YEAR_DAY)}
            onChange={(event) =>
              setEndDate(
                DateTime.fromISO(event.target.value as string).toJSDate()
              )
            }
          />
          <Box>
            <InputLabel id="select-multiple-cast">ドライバー名</InputLabel>
            <Select
              multiple
              value={selectDrivers}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setSelectDrivers((prev: string[]) => {
                  if (
                    prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [];
                  } else if (
                    !prev.includes("すべて") &&
                    (event.target.value as string[]).indexOf("すべて") !== -1
                  ) {
                    return [
                      ...drivers.map((driver) => String(driver.driverId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length ===
                      drivers.length &&
                    (event.target.value as string[]).indexOf("すべて") === -1
                  ) {
                    return [
                      ...drivers.map((driver) => String(driver.driverId)),
                      "すべて",
                    ];
                  } else if (
                    (event.target.value as string[]).length <= drivers.length
                  ) {
                    return (event.target.value as string[]).filter(
                      (name) => name !== "すべて"
                    );
                  } else {
                    return event.target.value as string[];
                  }
                });
              }}
              input={<Input id="select-multiple-driver" />}
              style={{ width: "200px" }}
              renderValue={(selected) => {
                if ((selected as string[]).includes("すべて")) {
                  return "すべて";
                } else {
                  return drivers
                    .filter((driver) =>
                      (selected as string[]).includes(String(driver.driverId))
                    )
                    .map((driver) => driver.name)
                    .join(", ");
                }
              }}
            >
              <MenuItem key={"すべて"} value={"すべて"}>
                <Checkbox
                  name="all"
                  checked={selectDrivers.indexOf("すべて") > -1}
                />
                <ListItemText primary={"すべて"} />
              </MenuItem>
              {drivers.map((driver) => (
                <MenuItem key={driver.driverId} value={String(driver.driverId)}>
                  <Checkbox
                    checked={
                      selectDrivers.indexOf(String(driver.driverId)) !== -1
                    }
                  />
                  <ListItemText primary={driver.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            component={Link}
            to="/driverShift/bulkInsert"
          >
            一括追加画面へ
          </Button>
        </Box>
      </Box>
      <DriverShiftTable
        title={"ドライバー出退勤"}
        formId="driverShiftForm"
        forms={forms}
        addFunc={(formData) => addDriverShift(companyId, formData)}
        updateFunc={(formData) => updateDriverShift(companyId, formData)}
        deleteFunc={(item) =>
          deleteDriverShift(companyId, {
            driverShiftId: item?.driverShiftId,
          })
        }
        defaultValue={{ status: "unconfirmed" }}
        rows={headers}
        data={filterDriverShifts}
        addType={CreateDriverShiftReq}
        updateType={UpdateDriverShiftReq}
        values={[
          (c) =>
            drivers.find((driver) => driver.driverId === c.driverId)?.name ||
            "未設定",
          (c) =>
            drivers.find((driver) => driver.driverId === c.driverId)?.tel ||
            "未設定",
          (c) =>
            `${DateTimeUtils.toFormatAsLocalTimezone(
              c.planWorkStart,
              FORMAT_TYPE.YEAR_DATE_TIME
            )} ~ ${
              c.planWorkEnd
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.planWorkEnd,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            }`,
          (c) =>
            `${
              c.actualWorkStart
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.actualWorkStart,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            } ~ ${
              c.actualWorkEnd
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.actualWorkEnd,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            }`,
          (c) =>
            `${
              c.workOffStart
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.workOffStart,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            } ~ ${
              c.workOffEnd
                ? DateTimeUtils.toFormatAsLocalTimezone(
                    c.workOffEnd,
                    FORMAT_TYPE.YEAR_DATE_TIME
                  )
                : "未設定"
            }`,
          (c) => EnumUtils.mapToEnum(ShiftStatus, c.status) || "未設定",
          (c) => {
            const driver = drivers.find((d) => d.driverId === c.driverId);
            if (
              !driver ||
              (!driver.carType && !driver.carColor && !driver.carNumber)
            )
              return "未設定";
            return `${driver.carType || ""} ${driver.carNumber || ""} ${
              driver.carColor || ""
            }`;
          },
          (c) => c.driver?.remarks || "",
          (c) => c.driverMemo || "",
          (c) =>
            EnumUtils.mapToEnum(CastScheduleItemColor, c.scheduleItemColor) ||
            "未設定",
        ]}
      />
    </>
  );
};

const DriverShiftTable = styled<
  React.FC<
    CommonTableProps<DriverShiftRes, CreateDriverShiftReq, UpdateDriverShiftReq>
  >
>(CommonTable)`
  margin-top: 24px;
`;

export default DriverShift;
