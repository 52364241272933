import { Expose, Type } from "class-transformer";
import CompanyGroupRes from "../companyGroup/CompanyGroupRes";

export default class CompanyRes {
  @Expose() readonly companyId!: number;
  @Expose() readonly companyGroupId!: number;
  @Expose() readonly name!: string;
  @Expose() readonly tel!: string;
  @Expose() readonly email!: string;
  @Expose() readonly kana!: string;
  @Expose() readonly representativeName!: string;
  @Type(() => Boolean)
  @Expose()
  readonly isCallCenterContract!: boolean;
  @Expose() readonly changeReserve!: number;
  @Type(() => CompanyGroupRes)
  @Expose()
  readonly companyGroup!: CompanyGroupRes;
  @Type(() => Boolean)
  @Expose()
  readonly pointShopFlag!: boolean;

  @Type(() => Boolean)
  @Expose()
  readonly guestCategoryShopFlag!: boolean;
  @Expose() readonly sort!: number | null;
  @Expose() readonly changeDateTime!: string;
}
