import {
  IsBoolean,
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
} from "class-validator";
import Spec from "constants/Spec";
import { DataValidationError } from "constants/DataValidationError";

export default class CreateCompanyReq {
  @IsNotEmpty({
    message: "",
  })
  companyGroupId: number;
  @MaxLength(Spec.maxLength.company.name, {
    message: "",
    context: {
      constraint1: Spec.maxLength.company.name,
    },
  })
  @IsNotEmpty({ message: "" })
  name!: string;

  @MaxLength(Spec.maxLength.company.kana, {
    message: "",
    context: {
      constraint1: Spec.maxLength.company.kana,
    },
  })
  @IsNotEmpty({ message: "" })
  kana!: string;

  @MaxLength(Spec.maxLength.company.email, {
    message: "",
    context: {
      constraint1: Spec.maxLength.company.email,
    },
  })
  @IsNotEmpty({ message: "" })
  @IsEmail()
  email!: string;

  @MaxLength(Spec.maxLength.company.tel, {
    message: "",
    context: {
      constraint1: Spec.maxLength.company.tel,
    },
  })
  @IsNotEmpty({ message: "" })
  tel!: string;

  @MaxLength(Spec.maxLength.company.representativeName, {
    message: "",
    context: {
      constraint1: Spec.maxLength.company.representativeName,
    },
  })
  @IsNotEmpty({ message: "" })
  representativeName!: string;

  @IsNotEmpty({ message: "" })
  @IsBoolean()
  isCallCenterContract!: boolean;

  @IsBoolean()
  pointShopFlag!: boolean;

  @IsBoolean()
  guestCategoryShopFlag!: boolean;

  @IsOptional()
  @IsNumber()
  sort!: number | null;

  @IsNotEmpty({ message: DataValidationError.DATA_IS_EMPTY })
  changeDateTime!: string;

  constructor(arg: {
    companyGroupId: number;
    name: string;
    kana: string;
    email: string;
    tel: string;
    representativeName: string;
    isCallCenterContract: boolean;
    pointShopFlag: boolean;
    guestCategoryShopFlag: boolean;
    sort: number | null;
    changeDateTime: string;
  }) {
    arg = arg || {};
    this.companyGroupId = arg.companyGroupId;
    this.name = arg.name;
    this.kana = arg.kana;
    this.email = arg.email;
    this.tel = arg.tel;
    this.representativeName = arg.representativeName;
    this.isCallCenterContract = arg.isCallCenterContract;
    this.pointShopFlag = arg.pointShopFlag;
    this.guestCategoryShopFlag = arg.guestCategoryShopFlag;
    this.sort = arg.sort;
    this.changeDateTime = arg.changeDateTime;
  }
}
