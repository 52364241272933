import { CssBaseline } from "@material-ui/core";
import Footer from "components/Footer";
import React from "react";
import styled from "styled-components";
import SpHeader from "components/Sp/SpHeader";
const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  margin: 0 auto;
`;

const MainContent = styled.div`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
`;

const SpMain: React.FC = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <AppContent>
        <SpHeader />
        <MainContent>{children}</MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};
export default SpMain;
